.stick-to-calendar {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 1%;
    left: 0;
    right: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
}

.island-panel {
    width: max-content;
    border: 3px solid lightgrey;
    border-radius: 30px;
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.island-panel-container {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 10px
}

.schedule-thumbnail-img {
    width: 40px;
    height: 45px;
}

.island-nav-button-font {
    font-size: 11px !important;
}

@media (max-width: 500px) {
    .island-panel-container {
        gap: 5px;
    }
    .island-nav-button-font {
        font-size: 8.5px !important;
    }
}