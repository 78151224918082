.Page {
    width: 100%;
    min-height: 100vh;
    background-color: #e7ebf0;
    font-size: calc(10px + 2vmin);
}

.main-page-flexbox {
    display: flex;
    gap: 0px 20px;
}

.main-page-margin {
    margin: 16px;
}

.main-page-left {
    display: inline-block;
    width: min-content;
}
.main-page-right {
    display: inline-block;
    flex-grow: 1;
}

#top-nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#logo {
    font-family: 'M PLUS 1p', 'Helvetica', 'Arial', sans-serif;
    margin-left: 40px;
}
#logo-ubc {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: bold;
    color: #2b81ab;
    display: inline-block;
}
#logo-course-scheduler {
    font-size: 1.3rem;
    line-height: 1.3rem;
    display: inline-block;
    color: #5b5b5b;
}

#nav-actions {
    margin-right: 30px;
    display: flex;
    column-gap: 40px;
}

.App-link {
    color: #61dafb;
}

.calendar-table {
    text-align: center;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

div.cal-background-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #ebebeb;
    border-width: 1px;
}

div.cal-background-grid.first-row {
    border-width: 0px 1px 0px 1px;
    font-weight: bold;
}
div.cal-background-grid.second-row {
    border-width: 0px 1px 1px 1px;
}
div.cal-background-grid.last-row {
    border-width: 1px 1px 0px 1px;
}
div.cal-background-grid.first-col {
    border-width: 0px 0px 0px 0px;
    justify-content: right;
    padding-right: 1em;
}

div.cal-background-grid.second-col {
    border-width: 1px 1px 1px 0px;
}
div.cal-background-grid.last-col {
    border-width: 1px 0px 1px 1px;
}
div.cal-background-grid.first-col.first-row {
    border-width: 0px;
    border-top-left-radius: 20px;
}
div.cal-background-grid.second-col.first-row {
    border-width: 0px 1px 0px 0px;
}
div.cal-background-grid.second-col.second-row {
    border-width: 0px 1px 1px 0px;
}
div.cal-background-grid.second-col.last-row {
    border-width: 1px 1px 0px 0px;
}
div.cal-background-grid.last-col.first-row {
    border-width: 0px 0px 0px 1px;
    border-top-right-radius: 20px;
}
div.cal-background-grid.last-col.second-row {
    border-width: 0px 0px 1px 1px;
}
div.cal-background-grid.last-col.last-row {
    border-width: 1px 0px 0px 1px;
}

.grid-calendar-container {
    display: grid;
    grid-template-columns: [times] 5em [Mon] 1fr [Tue] 1fr [Wed] 1fr [Thu] 1fr [Fri] 1fr;
    grid-auto-rows: 30px;
    grid-gap: 0px;
    padding: 1rem;
    font-family: 'M PLUS 1p', 'Helvetica', 'Arial', sans-serif;
    color: #5b5b5b;
    font-size: 1rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
/* Following style contains overlap in the mobile
   preventing overlap from sticking out too much to the right */
.overlap-column {
    z-index: 3;
}

/* Following style contains overlap in the mobile
   preventing overlap from sticking out too much to the right */
.overlap-column {
    z-index: 3;
}

.grid-overlap-container {
    display: grid;
    grid-template-columns: [times] 5em 1fr;
    grid-auto-rows: 30px;
    grid-gap: 0px;
}

.cal-slot {
    z-index: 4;
    margin: 2px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cal-slot-no-drag-and-drop {
    container-type: inline-size;
    container-name: timeslot;
}

.cal-slot-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1em;
    align-items: center;
}

@container timeslot (inline-size < 80px) {
    .cal-slot-container {
        font-size: 12px;
        text-overflow: clip;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }
}

@container timeslot (inline-size < 60px) {
    .cal-slot-container {
        font-size: 11px;
    }
}

@container timeslot (inline-size < 50px) {
    .cal-slot-container {
        font-size: 10px;
    }
}

@container timeslot (inline-size < 40px) {
    .cal-slot-container {
        font-size: 9px;
    }
}

@container timeslot (inline-size < 30px) {
    .cal-slot-container {
        font-size: 8px;
    }
    .cal-slot-status {
        align-self: flex-start;
    }
}

@container timeslot (inline-size < 20px) {
    .cal-slot-container {
        font-size: 6px;
    }
}

@container timeslot (inline-size < 10px) {
    .cal-slot-container {
        font-size: 4px;
    }
}

.outlined-cal-slot {
    border: 2px dashed rgba(89, 89, 89, 0.5);
    color: grey;
    transition: color 0.2s, border-color 0.2s, transform 0.1s;
    /* TODO: max-width: 10vw */
    /* TODO: max-width: 10vw */
}
/*See NextTimeSlot.jsx provideStyle() for drag and drop hover style*/

.solid-cal-slot {
    background-color: #bfeaff;
    color: #2b81ab;
    transition: transform 0.2s linear 0s;
}
.solid-cal-slot:hover {
    transform: scale(0.95);
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

#pointer-on-hover:hover {
    cursor: pointer;
}

.cal-slot-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5px;
}

.cal-slot-status,
.cal-slot-status-overlap {
    font-size: 0.8em;
}

.mini-map {
    transition: transform 0.2s linear 0s;
}
.mini-map:hover {
    transform: scale(1.4);
}

.chosen-course {
    font-size: 1rem;
    /* background-color: darkorchid; */
    width: 100%;
    margin-top: 20px;
    display: flex;
}
.chosen-course-start,
.chosen-course-mid,
.chosen-course-end {
    font-size: 1rem;
    height: 50px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
}

.chosen-course-start {
    background-color: #bfeaff;
    border-radius: 10px 0px 0px 10px;
    color: #2b81ab;
    display: inline-block;
    white-space: break-spaces;
    padding: 0 1rem 0 1rem;
    width: 4.5rem;
}
.chosen-course-mid {
    background-color: #f6f6f6;
    display: inline-flex;
    flex-grow: 1;
    padding: 0 1rem 0 1rem;
}
.chosen-course-mid-top {
    background-color: #f6f6f6;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9rem;
    width: calc(268px - 45px - 4.5rem - 2rem);
}
.chosen-course-mid-bottom {
    color: #807d7d;
}
.chosen-course-end {
    background-color: #f6f6f6;
    border-radius: 0px 10px 10px 0px;
    padding-right: 5px;
    display: inline-flex;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #e7ebf0;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #e7ebf0;
}

.schedule-options-container {
    margin: auto;
    width: min-content;
}

/* Class Info */
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-row-align-center {
    display: flex;
    align-items: center;
}

.flex-row {
    display: flex;
}

.instruction-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200;
}

.pulseYellow {
    -webkit-animation: pulseYellow 1000ms infinite alternate;
    animation: pulseYellow 750ms infinite alternate;
    border-radius: 15px;
}
@-webkit-keyframes pulseYellow {
    0% {
        border: 5px solid #ffffee;
    }
    50% {
        border: 5px solid #ffff66;
    }
    100% {
        border: 5px solid #ffff00;
    }
}
@keyframes pulseYellow {
    0% {
        border: 5px solid #ffffee;
    }
    50% {
        border: 5px solid #ffff66;
    }
    100% {
        border: 5px solid #ffff00;
    }
}

.mobile-no-display {
    display: 'inline';
}

@media (max-width: 850px) {
    .main-page-flexbox {
        flex-direction: column;
    }
    .main-page-left {
        width: 100% !important;
        margin-bottom: 1rem;
    }
}

@media (max-width: 850px) {
    .main-page-flexbox {
        flex-direction: column;
    }
    .main-page-left {
        width: 100% !important;
        margin-bottom: 1rem;
    }
}

@media (max-width: 510px) {
    #logo-ubc {
        font-size: 2rem;
        line-height: 4rem;
    }
    #logo-course-scheduler {
        font-size: 0.8rem;
        line-height: 1rem;
    }
    #nav-actions {
        margin-right: 20px;
        column-gap: 10px;
    }
}

/* mobile view dnd: we need font size to be
    responsive and small enough */
@media (max-width: 430px) {
    .mobile-no-display {
        display: none;
    }
    .main-page-margin {
        margin: 7px;
    }
    .cal-slot:not(.cal-slot-no-drag-and-drop) {
        margin: 1px;
        border-radius: 10px;
        font-size: 12px;
    }
    .grid-calendar-container {
        grid-template-columns: [times] 2.9em [Mon] 1fr [Tue] 1fr [Wed] 1fr [Thu] 1fr [Fri] 1fr;
    }
    div.cal-background-grid.first-row {
        font-size: 12px;
    }
    div.cal-background-grid.first-col {
        font-size: 12px;
        padding-right: 0px;
        justify-content: center;
    }
}

@media (max-width: 400px) {
    .grid-calendar-container {
        grid-template-columns: [times] 2.6em [Mon] 1fr [Tue] 1fr [Wed] 1fr [Thu] 1fr [Fri] 1fr;
    }
    .cal-slot:not(.cal-slot-no-drag-and-drop) {
        font-size: 11px;
    }
    div.cal-background-grid.first-row {
        font-size: 11px;
    }
    div.cal-background-grid.first-col {
        font-size: 11px;
    }
}

@media (max-width: 360px) {
    .cal-slot:not(.cal-slot-no-drag-and-drop) {
        font-size: 10px;
    }
    div.cal-background-grid.first-row {
        font-size: 10px;
    }
    div.cal-background-grid.first-col {
        font-size: 10px;
    }
}

@media (max-width: 350px) {
    .cal-slot:not(.cal-slot-no-drag-and-drop) {
        font-size: 9px;
    }
    div.cal-background-grid.first-row {
        font-size: 9px;
    }
    div.cal-background-grid.first-col {
        font-size: 9px;
    }
}
