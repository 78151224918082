.courseInfoContainer {
    border-radius: 8px 8px 0px 0px;
    padding: 0.25rem;
}

.courseInfoTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 23px;
}

.courseInfoTitle {
    max-width: 250px;
    padding-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    font-size: 1rem;
    font-family: 'M PLUS 1p', 'Helvetica', 'Arial', sans-serif;
}

.courseInfoDescription {
    max-width: 250px;
    padding-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 0.78rem;
    font-family: 'M PLUS 1p', 'Helvetica', 'Arial', sans-serif;
}

.courseInfoSpacer {
    padding-bottom: 15px;
}

.dialogRestrictedRowsContainer {
    max-height: 150;
    overflow: auto;
    margin-inline: 10%;
    margin-bottom: 20px;
    padding-inline: 10px;
    padding-bottom: 20px;
    border: 1px solid grey;
}
