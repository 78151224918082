.cross-out-dark {
    z-index: 3;
    /* animation: glow 1s infinite alternate;
    border: 3px solid red;
    border-color: rgba(255, 10, 10, 0.84);
    box-shadow: 0 0 2px 2px red; */
    border: 1px solid red;
    background-color: rgba(233, 0, 0, 0.6);
    border-radius: 10px;
    transition: 0.5s;
}

.cross-out-light {
  z-index: 3;
  border: 1px solid red;
  background-color: rgba(233, 0, 0, 0.4);
  border-radius: 10px;
  transition: 0.5s;
}

@keyframes glow {
    from {
      background-color: rgba(233, 0, 0, 0);
    }
    to {
      background-color: rgba(233, 0, 0, 0.5);

    }
}


